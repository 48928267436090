<template>
  <footer>
    <div class="container__fw">
      <div class="upper__section">
        <div class="logo__wrapper">
          <img 
            src="@/assets/img/logo-white.svg" 
            alt="Logo of the Mauritius Software Craftsmanship Community"
            title="Logo of the Mauritius Software Craftsmanship Community"
            loading="lazy"
          />
        </div>
        <div class="logo">
          <h3>Mauritius Software Craftsmanship Community</h3>
          <h4>Jobs In Tech</h4>
        </div>

        <div class="social__media">
          <a
            href="https://twitter.com/mscraftsman"
            class="icon"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 1.77562C17.3383 2.07723 16.6261 2.28069 15.8789 2.37173C16.6419 1.90338 17.2268 1.16151 17.5027 0.276515C16.7891 0.710771 15.9991 1.02588 15.1577 1.19598C14.4843 0.460109 13.5244 0 12.4622 0C10.423 0 8.76962 1.69543 8.76962 3.78691C8.76962 4.08328 8.80214 4.37216 8.86536 4.6498C5.79611 4.49168 3.07472 2.98434 1.25327 0.693161C0.935388 1.25219 0.753426 1.90263 0.753426 2.59729C0.753426 3.91093 1.40527 5.07019 2.3962 5.74911C1.79112 5.72925 1.22148 5.55877 0.723464 5.27514C0.723098 5.29088 0.723098 5.30699 0.723098 5.3231C0.723098 7.15754 1.9961 8.68774 3.68565 9.03619C3.3758 9.12237 3.04951 9.16883 2.71262 9.16883C2.47439 9.16883 2.2431 9.14523 2.01766 9.10064C2.48791 10.605 3.85153 11.7002 5.46727 11.7305C4.2034 12.7463 2.61141 13.3518 0.880945 13.3518C0.583156 13.3518 0.28902 13.3338 0 13.2986C1.63474 14.3735 3.57567 15 5.66092 15C12.4538 15 16.168 9.2299 16.168 4.22566C16.168 4.06155 16.1647 3.89781 16.1577 3.7352C16.879 3.2024 17.5053 2.53509 18 1.77562Z"
                fill="var(--footer-icons-color)"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/MauritiusSoftwareCraftsmanshipCommunity/"
            class="icon"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="8"
              height="15"
              viewBox="0 0 8 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33333 5V3.51172C5.33333 2.83984 5.49167 2.5 6.60417 2.5H8V0H5.67083C2.81667 0 1.875 1.22656 1.875 3.33203V5H0V7.5H1.875V15H5.33333V7.5H7.68333L8 5H5.33333Z"
                fill="var(--footer-icons-color)"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/groups/5033639/"
            class="icon"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8088 14.9883V8.92684C12.8088 8.92684 12.6293 7.30536 11.0605 7.30536C9.49269 7.30536 9.20588 8.84682 9.20588 8.84682V14.9883H5.97466L6.02736 4.97561H9.15321L9.12783 6.23416C9.12783 6.23416 9.77957 4.58927 12.2351 4.58927C14.6898 4.58927 15.802 6.00001 16.0088 8.63806C16.0088 11.2771 16.0088 14.9883 16.0088 14.9883H12.8088ZM2.01172 3.55318C0.901463 3.55318 0 2.75902 0 1.77758C0 0.796128 0.901463 0 2.01172 0C3.12198 0 4.02344 0.796097 4.02344 1.77758C4.02344 2.75905 3.12195 3.55318 2.01172 3.55318ZM4.00587 15H0.0331863V5.00099L4.00587 4.97561V15Z"
                fill="var(--footer-icons-color)"
              />
            </svg>
          </a>
          <a
            href="https://github.com/mscraftsman/"
            class="icon"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0C3.58203 0 0 3.44305 0 7.6896C0 11.0876 2.292 13.9693 5.47119 14.9859C5.87109 15.0563 6.01709 14.8188 6.01709 14.6161C6.01709 14.433 6.00977 13.8266 6.00586 13.1846C3.78125 13.6492 3.31103 12.2778 3.31103 12.2778C2.94678 11.3898 2.42284 11.1533 2.42284 11.1533C1.6958 10.6765 2.478 10.6858 2.478 10.6858C3.28122 10.7393 3.70409 11.4781 3.70409 11.4781C4.41797 12.6533 5.57714 12.3135 6.03222 12.1164C6.10494 11.6208 6.31197 11.281 6.54003 11.0895C4.76416 10.8952 2.896 10.2353 2.896 7.28878C2.896 6.44866 3.20802 5.76344 3.71875 5.22511C3.6372 5.02987 3.36181 4.24748 3.79784 3.18914C3.79784 3.18914 4.46875 2.98263 5.99803 3.97763C6.63572 3.80632 7.31981 3.72184 7.99998 3.71902C8.67967 3.72184 9.36423 3.80725 10.0029 3.97856C11.5293 2.98263 12.2012 3.19007 12.2012 3.19007C12.6387 4.24936 12.3633 5.0308 12.2812 5.22511C12.7939 5.76344 13.1035 6.44868 13.1035 7.28878C13.1035 10.2428 11.2324 10.8933 9.4502 11.0838C9.7383 11.3223 9.99317 11.7897 9.99317 12.5069C9.99317 13.5347 9.98242 14.3636 9.98242 14.617C9.98242 14.8216 10.1279 15.061 10.5332 14.9859C13.71 13.9674 16 11.0857 16 7.6896C16 3.44305 12.418 0 8 0Z"
                fill="var(--footer-icons-color)"
              />
            </svg>
          </a>
          <a
            href="https://www.youtube.com/MauritiusSoftwareCraftsmanshipCommunity"
            class="icon"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="22"
              height="15"
              viewBox="0 0 22 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.1139 3.23597C21.1139 3.23597 20.9054 1.76632 20.2658 1.11894C19.4551 0.269664 18.5459 0.265636 18.1291 0.215873C15.1448 1.65739e-08 10.6681 0 10.6681 0H10.6586C10.6586 0 6.18213 1.65739e-08 3.19758 0.215873C2.78076 0.265636 1.87201 0.269664 1.06088 1.11894C0.42132 1.76632 0.213267 3.23597 0.213267 3.23597C0.213267 3.23597 0 4.96177 0 6.68757V8.30579C0 10.0314 0.213267 11.7572 0.213267 11.7572C0.213267 11.7572 0.42132 13.2268 1.06088 13.8742C1.87201 14.7237 2.93763 14.6965 3.41227 14.7855C5.11864 14.9493 10.6633 15 10.6633 15C10.6633 15 15.1448 14.9931 18.1291 14.7775C18.5459 14.7277 19.4551 14.7237 20.2658 13.8742C20.9054 13.2268 21.1139 11.7572 21.1139 11.7572C21.1139 11.7572 21.3267 10.0314 21.3267 8.30579V6.68757C21.3267 4.96177 21.1139 3.23597 21.1139 3.23597ZM8.46171 10.2662L8.46077 4.2741L14.2232 7.28045L8.46171 10.2662Z"
                fill="var(--footer-icons-color)"
              />
            </svg>
          </a>
        </div>
      </div>

      <div class="lower__section">
        <nav class="footer__nav">
          <ul>
            <li v-for="(item, index) in items" :key="index">
              <router-link :to="item.path">{{ item.text }}</router-link>
            </li>
          </ul>
        </nav>

        <div class="signature">
          Crafted with
          <span @click="switchTheme">
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.49936 12.9998C-7.13174 5.26962 3.44992 -4.34084 7.49936 2.13163C11.5495 -4.34084 22.1318 5.26962 7.49936 12.9998Z"
                  fill="var(--footer-heart-color)"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="15" height="13" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          in Mauritius
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      items: [
        {
          path: "/privacy",
          text: "Privacy Policy"
        },
        {
          path: "/terms",
          text: "Terms of Use"
        },
        {
          path: "/contact",
          text: "Contact"
        }
      ]
    };
  },
  methods: {
    ...mapActions({ switchTheme: "shared/switchTheme" })
  }
};
</script>

<style lang="scss" scoped>
footer {
  background: var(--footer-background-color);
  padding: calc(var(--gutter) * 2) 0;

  .upper__section {
    text-align: center;
    border-bottom: 1px solid var(--footer-border-color);
    padding-bottom: var(--gutter);

    .logo__wrapper {
      img {
        width: 60px;
        margin-bottom: 10px;
      }
    }

    .logo {
      margin-bottom: var(--gutter);

      h3 {
        text-transform: uppercase;
        color: var(--footer-title-color);
        font-size: 16px;
        margin: 0;
      }

      h4 {
        color: var(--footer-subtitle-color);
        text-transform: uppercase;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: normal;
        text-align: center;
        letter-spacing: 0.38em;
        margin: 0;
      }
    }

    .social__media {
      .icon {
        margin-right: 15px;

        img,
        svg {
          height: 20px;
          transition: all 0.1s ease-in-out;
        }

        &:hover {
          img,
          svg {
            transform: scale(1.3);
          }
        }
      }
    }
  }

  .lower__section {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    padding-top: var(--gutter);

    .footer__nav {
      ul {
        padding: 0;
        margin: 0;

        li {
          list-style-type: none;
          display: inline;
          padding: 0;
          margin: 0;

          a {
            margin-right: var(--gutter);
            color: var(--footer-text-color);
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: normal;
            text-decoration: none;
          }
        }
      }
    }

    .signature {
      text-align: right;
      color: var(--footer-text-color);
      font-size: 13px;
    }
  }
}

@media (max-width: 768px) {
  footer {
    .lower__section {
      grid-template-columns: 1fr;
      .footer__nav {
        margin-bottom: var(--gutter);

        ul {
          li {
            display: block;
            text-align: center;

            a {
              display: block;
            }
          }
        }
      }

      .signature {
        text-align: center;
      }
    }
  }
}
</style>
